define("jordan-hawker/components/dj-event", ["exports", "ember-interactivity/mixins/component-interactivity"], function (_exports, _componentInteractivity) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_componentInteractivity.default, {
    localClassNames: 'dj-event',
    event: null,
    danceStyles: Ember.computed('event.styles', function () {
      var styles = this.get('event.styles');

      if (styles) {
        return this.get('event.styles').join(', ');
      }
    }),
    dates: Ember.computed('event.dates', function () {
      var dates = this.get('event.dates');

      if (dates) {
        return this.get('event.dates').join(', ');
      }
    }),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      Ember.run.scheduleOnce('afterRender', this, this.reportInteractive);
    }
  });

  _exports.default = _default;
});