define("jordan-hawker/components/sliding-carousel", ["exports", "ember-interactivity/mixins/component-interactivity"], function (_exports, _componentInteractivity) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_componentInteractivity.default, {
    localClassNames: 'sliding-carousel',
    index: 0,
    init: function init() {
      this._super.apply(this, arguments);

      this.scheduleChange();
    },
    scheduleChange: function scheduleChange() {
      // Change every 30 seconds
      Ember.run.later(this, this.changeIndex, 20000);
    },
    changeIndex: function changeIndex() {
      if (!this.isDestroyed) {
        this.moveRight();
        this.scheduleChange();
      }
    },
    leftIndex: Ember.computed('index', 'content.[]', function () {
      var index = this.index;

      if (index > 0) {
        return index - 1;
      }

      return this.get('content.length') - 1;
    }),
    rightIndex: Ember.computed('index', 'content.[]', function () {
      var index = this.index;

      if (index < this.get('content.length') - 1) {
        return index + 1;
      }

      return 0;
    }),
    leftItem: Ember.computed('content.[]', 'leftIndex', function () {
      return this.content.objectAt(this.leftIndex);
    }),
    mainItem: Ember.computed('content.[]', 'index', function () {
      return this.content.objectAt(this.index);
    }),
    rightItem: Ember.computed('content.[]', 'rightIndex', function () {
      return this.content.objectAt(this.rightIndex);
    }),
    moveLeft: function moveLeft() {
      this.set('index', this.leftIndex);
    },
    moveRight: function moveRight() {
      this.set('index', this.rightIndex);
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      Ember.run.scheduleOnce('afterRender', this, this.reportInteractive);
    },
    actions: {
      moveLeft: function moveLeft() {
        this.moveLeft();
      },
      moveRight: function moveRight() {
        this.moveRight();
      }
    }
  });

  _exports.default = _default;
});