define("jordan-hawker/templates/components/dj-bio", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ypUUvokB",
    "block": "{\"symbols\":[],\"statements\":[[6,\"p\"],[8],[0,\"\\n  In 2006, DJ Axle (Jordan Hawker) discovered the life-changing experience of Dance. Since then,\\n  he has taken that passion to the next level by DJing all over the country, including Seattle\\n  Fusion Festival, DJX, Austin Fusion Party, Austin Blues Party, Bluesalicious, Show-Me Blues\\n  and more. Variety is the spice of life, and Jordan takes that to heart, pushing the envelope\\n  of genres we expect in dance; he particularly enjoys playing little-known artists to expand\\n  his audience's horizons and expectations. Representing the San Francisco Bay Area, DJ Axle's\\n  diverse musical style will keep you dancing 'til the lights go out!\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "jordan-hawker/templates/components/dj-bio.hbs"
    }
  });

  _exports.default = _default;
});