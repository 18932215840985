define("jordan-hawker/styles/components/splash-page", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "color-alt": "#9099A2",
    "color-base": "#E5E5E5",
    "color-dark": "#6D7993",
    "color-white": "#FFF",
    "small": "0.2rem",
    "large": "0.8rem",
    "splash-page": "_splash-page_spabcr",
    "content": "_content_spabcr",
    "logo": "_logo_spabcr",
    "title": "_title_spabcr",
    "version": "_version_spabcr",
    "description": "_description_spabcr"
  };
  _exports.default = _default;
});