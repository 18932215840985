define("jordan-hawker/models/twitch-stream", ["exports", "ember-data/model", "ember-data/attr"], function (_exports, _model, _attr) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    userId: (0, _attr.default)('string'),
    gameId: (0, _attr.default)('string'),
    type: (0, _attr.default)('string'),
    title: (0, _attr.default)('string'),
    viewerCount: (0, _attr.default)('number'),
    language: (0, _attr.default)('string'),
    thumbnailUrl: (0, _attr.default)('string'),
    startedAt: (0, _attr.default)('date'),
    communityIds: (0, _attr.default)()
  });

  _exports.default = _default;
});