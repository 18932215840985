define("jordan-hawker/templates/components/sliding-carousel", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "JmSqLIs8",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,\"div\"],[11,\"class\",[27,[[26,\"local-class\",[\"arrow left-arrow\"],[[\"from\"],[\"jordan-hawker/styles/components/sliding-carousel\"]]]]]],[3,\"action\",[[21,0,[]],\"moveLeft\"]],[8],[0,\"\\n  \"],[6,\"i\"],[10,\"class\",\"fa fa-2x fa-angle-left\"],[10,\"aria-hidden\",\"true\"],[8],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[13,1,[[22,[\"mainItem\"]]]],[0,\"\\n\"],[6,\"div\"],[11,\"class\",[27,[[26,\"local-class\",[\"arrow right-arrow\"],[[\"from\"],[\"jordan-hawker/styles/components/sliding-carousel\"]]]]]],[3,\"action\",[[21,0,[]],\"moveRight\"]],[8],[0,\"\\n  \"],[6,\"i\"],[10,\"class\",\"fa fa-2x fa-angle-right\"],[10,\"aria-hidden\",\"true\"],[8],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "jordan-hawker/templates/components/sliding-carousel.hbs"
    }
  });

  _exports.default = _default;
});