define("jordan-hawker/styles/components/footer-bar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "color-white": "#FFF",
    "small": "0.2rem",
    "footer-bar": "_footer-bar_vmn0y1",
    "github": "_github_vmn0y1",
    "brand-icon": "_brand-icon_vmn0y1"
  };
  _exports.default = _default;
});