define("jordan-hawker/components/tumblr-post-text", ["exports", "ember-tumblr/components/tumblr-post-text"], function (_exports, _tumblrPostText) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _tumblrPostText.default;
    }
  });
});