define("jordan-hawker/components/header-nav", ["exports", "jordan-hawker/components/header-bar"], function (_exports, _headerBar) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _headerBar.default.extend({
    localClassNames: 'header-nav'
  });

  _exports.default = _default;
});