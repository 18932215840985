define("jordan-hawker/templates/components/twitch-embed", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "uu8UVmbn",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[11,\"class\",[27,[[26,\"local-class\",[\"container\"],[[\"from\"],[\"jordan-hawker/styles/components/twitch-embed\"]]]]]],[8],[0,\"\\n  \"],[6,\"div\"],[11,\"class\",[27,[[26,\"local-class\",[\"tabs\"],[[\"from\"],[\"jordan-hawker/styles/components/twitch-embed\"]]]]]],[8],[0,\"\\n    \"],[6,\"button\"],[11,\"class\",[27,[[26,\"local-class\",[[26,\"concat\",[[22,[\"axleButtonClass\"]]],null]],[[\"from\"],[\"jordan-hawker/styles/components/twitch-embed\"]]]]]],[3,\"action\",[[21,0,[]],\"toggle\",\"axlehellfire\"]],[8],[0,\"AxleHellfire\"],[9],[0,\"\\n    \"],[6,\"button\"],[11,\"class\",[27,[[26,\"local-class\",[[26,\"concat\",[[22,[\"tiltedButtonClass\"]]],null]],[[\"from\"],[\"jordan-hawker/styles/components/twitch-embed\"]]]]]],[3,\"action\",[[21,0,[]],\"toggle\",\"boardgames\"]],[8],[0,\"BoardGames\"],[9],[0,\"\\n\"],[4,\"if\",[[22,[\"followUser\"]]],null,{\"statements\":[[0,\"      \"],[6,\"button\"],[11,\"class\",[27,[[26,\"local-class\",[[26,\"concat\",[[22,[\"followButtonClass\"]]],null]],[[\"from\"],[\"jordan-hawker/styles/components/twitch-embed\"]]]]]],[3,\"action\",[[21,0,[]],\"toggle\",[22,[\"followUser\",\"login\"]]]],[8],[1,[22,[\"followUser\",\"displayName\"]],false],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[9],[0,\"\\n  \"],[6,\"iframe\"],[11,\"src\",[27,[[20,\"embedUrl\"]]]],[10,\"frameborder\",\"0\"],[10,\"allowfullscreen\",\"\"],[8],[0,\"\\n  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "jordan-hawker/templates/components/twitch-embed.hbs"
    }
  });

  _exports.default = _default;
});