define("jordan-hawker/components/dj-events", ["exports", "ember-interactivity/mixins/component-interactivity"], function (_exports, _componentInteractivity) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_componentInteractivity.default, {
    store: Ember.inject.service(),
    events: null,
    loading: true,
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      this.set('events', []);
      this.store.findAll('event').then(function (events) {
        _this.set('events', events);

        _this.set('loading', false);
      });
    },
    weekendEvents: Ember.computed.filterBy('events', 'type', 'weekend'),
    recurringEvents: Ember.computed.filterBy('events', 'type', 'recurring'),
    isInteractive: function isInteractive(didReportInteractive) {
      return !this.loading && didReportInteractive('dj-event', {
        count: this.get('events.length')
      });
    }
  });

  _exports.default = _default;
});