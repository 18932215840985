
if (typeof FastBoot === 'undefined') {
  if (!runningTests) {
    require('jordan-hawker/app')['default'].create({"name":"jordan-hawker","version":"1.1.0+b3103d6e"});
  }
}

define('~fastboot/app-factory', ['jordan-hawker/app', 'jordan-hawker/config/environment'], function(App, config) {
  App = App['default'];
  config = config['default'];

  return {
    'default': function() {
      return App.create(config.APP);
    }
  };
});

