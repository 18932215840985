define("jordan-hawker/styles/components/twitch-embed", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "color-alt": "#9099A2",
    "color-dark": "#6D7993",
    "color-white": "#FFF",
    "very-small": "0.1rem",
    "small": "0.2rem",
    "medium": "0.4rem",
    "large": "0.8rem",
    "very-large": "1.2rem",
    "twitch-button-height": "3rem",
    "twitch-embed": "_twitch-embed_1ffvnk",
    "container": "_container_1ffvnk",
    "tabs": "_tabs_1ffvnk",
    "active": "_active_1ffvnk"
  };
  _exports.default = _default;
});