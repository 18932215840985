define("jordan-hawker/styles/layers", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "z-index-above": "100",
    "z-index-default": "1",
    "z-index-below": "-1"
  };
  _exports.default = _default;
});