define("jordan-hawker/adapters/twitch-follow", ["exports", "jordan-hawker/adapters/twitch"], function (_exports, _twitch) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _twitch.default.extend({
    pathForType: function pathForType() {
      return this._super.apply(this, arguments).replace('follows', 'users/follows');
    }
  });

  _exports.default = _default;
});