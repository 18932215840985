define("jordan-hawker/templates/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1p66LxNX",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[10,\"class\",\"index-header\"],[8],[0,\"\\n  \"],[6,\"p\"],[10,\"class\",\"index-welcome\"],[8],[0,\"\\n    Congratulations! You've made it to...well, nowhere. This is just my little corner of the web;\\n    feel free to poke around and see if there's anything you like. Check out my \"],[4,\"link-to\",[\"blog\"],null,{\"statements\":[[0,\"blog\"]],\"parameters\":[]},null],[0,\",\\n    browse my latest open-source \"],[4,\"link-to\",[\"projects\"],null,{\"statements\":[[0,\"projects\"]],\"parameters\":[]},null],[0,\", or go learn about my\\n    \"],[4,\"link-to\",[\"twitch\"],null,{\"statements\":[[0,\"Twitch\"]],\"parameters\":[]},null],[0,\" channels! Otherwise, just keep reading below to see my latest content:\\n  \"],[9],[0,\"\\n\\n  \"],[1,[20,\"newsletter-signup\"],false],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[1,[26,\"front-post\",null,[[\"formatDates\",\"post\",\"collapsible\"],[true,[22,[\"model\"]],false]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "jordan-hawker/templates/index.hbs"
    }
  });

  _exports.default = _default;
});