define("jordan-hawker/styles/components/header-nav", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "color-dark": "#6D7993",
    "color-white": "#FFF",
    "z-index-above": "100",
    "small": "0.2rem",
    "medium": "0.4rem",
    "logo-height": "3.2rem",
    "header-height": "3.2rem + 0.4rem",
    "header-nav": "_header-nav_e9mc63",
    "header-container": "_header-container_e9mc63",
    "links": "_links_e9mc63",
    "spacer": "_spacer_e9mc63",
    "header-logo": "_header-logo_e9mc63",
    "header-icon": "_header-icon_e9mc63"
  };
  _exports.default = _default;
});