define("jordan-hawker/models/recommendation", ["exports", "ember-data/model", "ember-data/attr"], function (_exports, _model, _attr) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    text: (0, _attr.default)('string'),
    firstName: (0, _attr.default)('string'),
    lastName: (0, _attr.default)('string'),
    role: (0, _attr.default)('string'),
    company: (0, _attr.default)('string')
  });

  _exports.default = _default;
});