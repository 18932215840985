define("jordan-hawker/components/twitch-info", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    localClassNames: 'twitch-info',
    store: Ember.inject.service(),
    user: null,
    username: null,
    videos: null,
    url: Ember.computed('user', function () {
      return "https://www.twitch.tv/".concat(this.get('user.login'));
    }),
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      if (this.username) {
        this.store.queryRecord('twitch-user', {
          login: this.username
        }).then(function (user) {
          _this.set('user', user);

          _this.store.query('twitch-video', {
            user_id: user.get('id')
          }).then(function (videos) {
            _this.set('videos', videos);
          });
        });
      }
    }
  });

  _exports.default = _default;
});