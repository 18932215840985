define("jordan-hawker/templates/components/resume-embed", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "QQgnxJ8s",
    "block": "{\"symbols\":[],\"statements\":[[6,\"p\"],[11,\"class\",[27,[[26,\"local-class\",[\"resume-link\"],[[\"from\"],[\"jordan-hawker/styles/components/resume-embed\"]]]]]],[8],[0,\"\\n  Note: Google Drive mangles the formatting of embedded documents somewhat.\\n  For a better version of this document, click \"],[6,\"a\"],[10,\"href\",\"http://jhawk.co/JordanHawker\"],[10,\"target\",\"_blank\"],[8],[0,\"here\"],[9],[0,\".\\n\"],[9],[0,\"\\n\\n\"],[6,\"div\"],[11,\"class\",[27,[[26,\"local-class\",[\"iframe-container\"],[[\"from\"],[\"jordan-hawker/styles/components/resume-embed\"]]]]]],[8],[0,\"\\n  \"],[6,\"iframe\"],[10,\"src\",\"https://docs.google.com/document/d/e/2PACX-1vRDZl11201ZpzQLkD8shFgOwo0ARixDMkS0XP1HY5H5-iH37pzrj35-RPMgoW1nvcV_-eWZ3p64Zx2o/pub?embedded=true\"],[8],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "jordan-hawker/templates/components/resume-embed.hbs"
    }
  });

  _exports.default = _default;
});