define("jordan-hawker/styles/components/twitch-info", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "color-dark": "#6D7993",
    "color-white": "#FFF",
    "small": "0.2rem",
    "medium": "0.4rem",
    "large": "0.8rem",
    "very-large": "1.2rem",
    "twitch-info": "_twitch-info_36gu4m",
    "header": "_header_36gu4m",
    "logo": "_logo_36gu4m",
    "title": "_title_36gu4m",
    "videos": "_videos_36gu4m",
    "video-thumbnail": "_video-thumbnail_36gu4m",
    "description": "_description_36gu4m"
  };
  _exports.default = _default;
});