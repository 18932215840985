define("jordan-hawker/components/left-nav-link", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var LeftNavLink = Ember.Component.extend({
    tagName: 'button',
    localClassNames: 'left-nav-link',
    router: Ember.inject.service(),
    click: function click() {
      this.router.transitionTo(this.name);
    }
  });
  LeftNavLink.reopenClass({
    positionalParams: ['name']
  });
  var _default = LeftNavLink;
  _exports.default = _default;
});