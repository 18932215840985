define("jordan-hawker/templates/components/splash-page", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "CNs0j33Y",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[11,\"class\",[27,[[26,\"local-class\",[\"content\"],[[\"from\"],[\"jordan-hawker/styles/components/splash-page\"]]]]]],[8],[0,\"\\n  \"],[6,\"a\"],[10,\"href\",\"http://jhawk.co/JHGitHub\"],[10,\"target\",\"_blank\"],[8],[0,\"\\n    \"],[6,\"img\"],[11,\"class\",[27,[[26,\"local-class\",[\"logo\"],[[\"from\"],[\"jordan-hawker/styles/components/splash-page\"]]]]]],[10,\"src\",\"assets/logo-medium.jpg\"],[10,\"alt\",\"Splash\"],[8],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[6,\"h1\"],[11,\"class\",[27,[[26,\"local-class\",[\"title\"],[[\"from\"],[\"jordan-hawker/styles/components/splash-page\"]]]]]],[8],[0,\"Jordan Hawker\"],[9],[0,\"\\n  \"],[6,\"span\"],[11,\"class\",[27,[[26,\"local-class\",[\"version\"],[[\"from\"],[\"jordan-hawker/styles/components/splash-page\"]]]]]],[8],[0,\"Version \"],[1,[26,\"app-version\",null,[[\"hideSha\"],[true]]],false],[9],[0,\"\\n  \"],[6,\"p\"],[11,\"class\",[27,[[26,\"local-class\",[\"description\"],[[\"from\"],[\"jordan-hawker/styles/components/splash-page\"]]]]]],[8],[0,\"The developer of ambitious web applications\"],[9],[0,\"\\n  \"],[6,\"code\"],[8],[0,\"\\n    npm install -g jordan-hawker\\n  \"],[9],[0,\"\\n  \"],[6,\"p\"],[11,\"class\",[27,[[26,\"local-class\",[\"description\"],[[\"from\"],[\"jordan-hawker/styles/components/splash-page\"]]]]]],[8],[0,\"More Productive Out Of The Box\"],[9],[0,\"\\n  \"],[6,\"iframe\"],[10,\"src\",\"https://ghbtns.com/github-btn.html?user=elwayman02&type=follow&count=true&size=large\"],[10,\"frameborder\",\"0\"],[10,\"scrolling\",\"0\"],[8],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[1,[20,\"footer-bar\"],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "jordan-hawker/templates/components/splash-page.hbs"
    }
  });

  _exports.default = _default;
});