define("jordan-hawker/templates/components/dj-events", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "JPW1Tw/i",
    "block": "{\"symbols\":[\"event\",\"event\"],\"statements\":[[6,\"h3\"],[8],[0,\"Exchanges & Workshops\"],[9],[0,\"\\n\\n\"],[6,\"div\"],[11,\"class\",[27,[[26,\"local-class\",[\"event-list\"],[[\"from\"],[\"jordan-hawker/styles/components/dj-events\"]]]]]],[8],[0,\"\\n\"],[4,\"each\",[[22,[\"weekendEvents\"]]],null,{\"statements\":[[0,\"    \"],[1,[26,\"dj-event\",null,[[\"event\"],[[21,2,[]]]]],false],[0,\"\\n\"]],\"parameters\":[2]},null],[9],[0,\"\\n\\n\"],[6,\"h3\"],[8],[0,\"Weekly/Recurring Events\"],[9],[0,\"\\n\\n\"],[6,\"div\"],[11,\"class\",[27,[[26,\"local-class\",[\"event-list\"],[[\"from\"],[\"jordan-hawker/styles/components/dj-events\"]]]]]],[8],[0,\"\\n\"],[4,\"each\",[[22,[\"recurringEvents\"]]],null,{\"statements\":[[0,\"    \"],[1,[26,\"dj-event\",null,[[\"event\"],[[21,1,[]]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "jordan-hawker/templates/components/dj-events.hbs"
    }
  });

  _exports.default = _default;
});