define("jordan-hawker/adapters/github-compare", ["exports", "ember-data-github/adapters/github-compare"], function (_exports, _githubCompare) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _githubCompare.default;
    }
  });
});