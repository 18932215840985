define("jordan-hawker/adapters/tumblr-post-photo", ["exports", "jordan-hawker/adapters/tumblr-post"], function (_exports, _tumblrPost) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _tumblrPost.default.extend({
    type: 'photo'
  });

  _exports.default = _default;
});