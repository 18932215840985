define("jordan-hawker/components/github-project", ["exports", "ember-interactivity/mixins/component-interactivity"], function (_exports, _componentInteractivity) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_componentInteractivity.default, {
    localClassNames: 'github-project',
    url: Ember.computed('repo.{homepage,htmlUrl}', function () {
      var homepage = this.get('repo.homepage');
      return homepage && homepage.includes('jhawk.co') ? homepage : this.get('repo.htmlUrl');
    }),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      Ember.run.scheduleOnce('afterRender', this, this.reportInteractive);
    }
  });

  _exports.default = _default;
});