define("jordan-hawker/styles/colors", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "color-white": "#FFF",
    "color-base": "#E5E5E5",
    "color-alt": "#9099A2",
    "color-dark": "#6D7993"
  };
  _exports.default = _default;
});