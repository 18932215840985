define("jordan-hawker/templates/components/newsletter-signup", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "cGEsDXLu",
    "block": "{\"symbols\":[],\"statements\":[[6,\"form\"],[10,\"action\",\"//jordanhawker.us10.list-manage.com/subscribe/post?u=eb274c60021dd5d2366e2e317&id=bfc7a28111\"],[10,\"method\",\"post\"],[10,\"id\",\"mc-embedded-subscribe-form\"],[10,\"name\",\"mc-embedded-subscribe-form\"],[10,\"class\",\"validate\"],[10,\"target\",\"_blank\"],[10,\"novalidate\",\"\"],[8],[0,\"\\n  \"],[6,\"div\"],[11,\"class\",[27,[[26,\"local-class\",[\"form\"],[[\"from\"],[\"jordan-hawker/styles/components/newsletter-signup\"]]]]]],[8],[0,\"\\n    \"],[6,\"label\"],[10,\"for\",\"mce-EMAIL\"],[8],[0,\"Subscribe For Future Content:\"],[9],[0,\"\\n    \"],[6,\"input\"],[11,\"class\",[27,[[26,\"local-class\",[\"email\"],[[\"from\"],[\"jordan-hawker/styles/components/newsletter-signup\"]]]]]],[10,\"value\",\"\"],[10,\"name\",\"EMAIL\"],[10,\"id\",\"mce-EMAIL\"],[10,\"placeholder\",\"email address\"],[10,\"required\",\"\"],[10,\"type\",\"email\"],[8],[9],[0,\"\\n    \"],[2,\" real people should not fill this in and expect good things - do not remove this or risk form bot signups\"],[0,\"\\n    \"],[6,\"div\"],[10,\"style\",\"position: absolute; left: -5000px;\"],[8],[0,\"\\n      \"],[6,\"input\"],[10,\"name\",\"b_eb274c60021dd5d2366e2e317_bfc7a28111\"],[10,\"tabindex\",\"-1\"],[10,\"value\",\"\"],[10,\"type\",\"text\"],[8],[9],[0,\"\\n    \"],[9],[0,\"\\n    \"],[6,\"input\"],[11,\"class\",[27,[[26,\"local-class\",[\"subscribe\"],[[\"from\"],[\"jordan-hawker/styles/components/newsletter-signup\"]]]]]],[10,\"value\",\"Subscribe\"],[10,\"name\",\"subscribe\"],[10,\"id\",\"mc-embedded-subscribe\"],[10,\"type\",\"submit\"],[8],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "jordan-hawker/templates/components/newsletter-signup.hbs"
    }
  });

  _exports.default = _default;
});