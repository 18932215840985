define("jordan-hawker/styles/components/dj-promo", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "large": "0.8rem",
    "very-large": "1.2rem",
    "promo-width": "50rem",
    "dj-promo": "_dj-promo_1cmu46",
    "soundcloud-embed": "_soundcloud-embed_1cmu46",
    "dj-info": "_dj-info_1cmu46",
    "dj-about": "_dj-about_1cmu46"
  };
  _exports.default = _default;
});