define("jordan-hawker/adapters/twitch", ["exports", "ember-data", "ember-inflector"], function (_exports, _emberData, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var RESTAdapter = _emberData.default.RESTAdapter;

  var _default = RESTAdapter.extend({
    host: 'https://api.twitch.tv/helix',
    clientId: 'gxz07x8f6xtut1t7aiv31tyw6qhnmq',
    headers: Ember.computed('clientId', function () {
      return {
        'Client-Id': this.clientId
      };
    }),
    pathForType: function pathForType(type) {
      return Ember.String.camelize((0, _emberInflector.pluralize)(type.replace('twitch', '')));
    }
  });

  _exports.default = _default;
});