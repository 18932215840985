define("jordan-hawker/components/tumblr-blog", ["exports", "ember-tumblr/components/tumblr-blog"], function (_exports, _tumblrBlog) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _tumblrBlog.default;
    }
  });
});