define("jordan-hawker/templates/post", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "iM/ocfb2",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[22,[\"model\",\"isLoaded\"]]],null,{\"statements\":[[0,\"  \"],[1,[26,\"tumblr-post-text\",null,[[\"post\",\"formatDates\"],[[22,[\"model\"]],true]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[1,[20,\"placeholder-post\"],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"],[1,[26,\"disqus-comments\",null,[[\"identifier\",\"title\"],[[22,[\"model\",\"id\"]],[22,[\"model\",\"title\"]]]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "jordan-hawker/templates/post.hbs"
    }
  });

  _exports.default = _default;
});