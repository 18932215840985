define("jordan-hawker/components/tumblr-post", ["exports", "ember-tumblr/components/tumblr-post"], function (_exports, _tumblrPost) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _tumblrPost.default;
    }
  });
});