define("jordan-hawker/templates/application", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2H37YmCn",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[22,[\"media\",\"isMobile\"]]],null,{\"statements\":[[0,\"  \"],[1,[20,\"header-nav\"],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[1,[20,\"header-bar\"],false],[0,\"\\n\"]],\"parameters\":[]}],[6,\"div\"],[10,\"class\",\"container\"],[8],[0,\"\\n\"],[4,\"if\",[[26,\"not\",[[22,[\"media\",\"isMobile\"]]],null]],null,{\"statements\":[[0,\"    \"],[1,[20,\"left-nav\"],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[6,\"main\"],[10,\"id\",\"content\"],[8],[0,\"\\n    \"],[1,[20,\"outlet\"],false],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[1,[26,\"bug-widget\",null,[[\"url\"],[\"https://github.com/elwayman02/jordan-hawker/issues/new\"]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "jordan-hawker/templates/application.hbs"
    }
  });

  _exports.default = _default;
});