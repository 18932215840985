define("jordan-hawker/templates/components/github-project", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "BtYPIqgR",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[8],[0,\"\\n  \"],[6,\"span\"],[11,\"class\",[27,[[26,\"local-class\",[\"title\"],[[\"from\"],[\"jordan-hawker/styles/components/github-project\"]]]]]],[8],[0,\"\\n    \"],[6,\"h4\"],[8],[0,\"\\n      \"],[6,\"a\"],[11,\"href\",[27,[[20,\"url\"]]]],[10,\"target\",\"_blank\"],[8],[1,[22,[\"repo\",\"name\"]],false],[9],[0,\"\\n      \"],[6,\"i\"],[10,\"class\",\"fa fa-1x fa-star\"],[10,\"aria-hidden\",\"true\"],[8],[9],[0,\" \"],[1,[22,[\"repo\",\"stargazersCount\"]],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[6,\"p\"],[8],[6,\"strong\"],[8],[1,[22,[\"repo\",\"description\"]],false],[9],[9],[0,\"\\n  \"],[6,\"p\"],[8],[0,\"Last Updated: \"],[1,[26,\"moment-format\",[[22,[\"repo\",\"updatedAt\"]],\"MM/DD/YYYY\"],null],false],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "jordan-hawker/templates/components/github-project.hbs"
    }
  });

  _exports.default = _default;
});