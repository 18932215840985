define("jordan-hawker/templates/projects", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "kT+gq9AT",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[10,\"class\",\"projects\"],[8],[0,\"\\n  \"],[6,\"p\"],[8],[0,\"Many of the projects below were used to build this website!\"],[9],[0,\"\\n\\n  \"],[1,[20,\"github-projects\"],false],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "jordan-hawker/templates/projects.hbs"
    }
  });

  _exports.default = _default;
});