define("jordan-hawker/components/dj-promo", ["exports", "ember-interactivity/mixins/component-interactivity"], function (_exports, _componentInteractivity) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_componentInteractivity.default, {
    localClassNames: 'dj-promo',
    isInteractive: function isInteractive(didReportInteractive) {
      return didReportInteractive('dj-bio');
    }
  });

  _exports.default = _default;
});