define("jordan-hawker/styles/components/left-nav", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "color-alt": "#9099A2",
    "color-dark": "#6D7993",
    "color-white": "#FFF",
    "very-small": "0.1rem",
    "left-nav-link-height": "3rem",
    "left-nav-footer-height": "(2*3rem + 0.1rem)",
    "left-nav": "_left-nav_1eycti",
    "left-nav-link-list": "_left-nav-link-list_1eycti",
    "left-nav-body": "_left-nav-body_1eycti",
    "left-nav-footer": "_left-nav-footer_1eycti"
  };
  _exports.default = _default;
});