define("jordan-hawker/styles/components/resume-embed", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "large": "0.8rem",
    "resume-embed": "_resume-embed_1s6ugj",
    "resume-link": "_resume-link_1s6ugj",
    "iframe-container": "_iframe-container_1s6ugj"
  };
  _exports.default = _default;
});