define("jordan-hawker/templates/components/front-post", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Y1sYlbzu",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[22,[\"post\"]]],null,{\"statements\":[[0,\"  \"],[1,[26,\"tumblr-post-text\",null,[[\"post\",\"formatDates\"],[[22,[\"post\"]],true]]],false],[0,\"\\n\\n  \"],[1,[26,\"disqus-comments\",null,[[\"identifier\",\"title\"],[[22,[\"post\",\"id\"]],[22,[\"post\",\"title\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[1,[20,\"placeholder-post\"],false],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "jordan-hawker/templates/components/front-post.hbs"
    }
  });

  _exports.default = _default;
});