define("jordan-hawker/components/header-bar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    localClassNames: 'header-bar',
    router: Ember.inject.service(),
    actions: {
      goToIndex: function goToIndex() {
        this.router.transitionTo('index');
      }
    }
  });

  _exports.default = _default;
});