define("jordan-hawker/styles/components/github-projects", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "github-projects": "_github-projects_42xtfi"
  };
  _exports.default = _default;
});