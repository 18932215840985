define("jordan-hawker/formats", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var hhmmss = {
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric'
  };
  var _default = {
    time: {
      hhmmss: hhmmss
    },
    date: {
      hhmmss: hhmmss
    },
    number: {
      EUR: {
        style: 'currency',
        currency: 'EUR'
      },
      USD: {
        style: 'currency',
        currency: 'USD'
      }
    }
  };
  _exports.default = _default;
});