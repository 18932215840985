define("jordan-hawker/router", ["exports", "jordan-hawker/config/environment", "ember-router-scroll"], function (_exports, _environment, _emberRouterScroll) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Router = Ember.Router.extend(_emberRouterScroll.default, {
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL,
    metrics: Ember.inject.service(),
    didTransition: function didTransition() {
      this._super.apply(this, arguments);

      this._trackPage();
    },
    _trackPage: function _trackPage() {
      var _this = this;

      Ember.run.scheduleOnce('afterRender', this, function () {
        var page = _this.url;

        var title = _this.getWithDefault('currentRouteName', 'unknown');

        _this.metrics.trackPage({
          page: page,
          title: title,
          event: 'pageViewed'
        });
      });
    }
  });
  Router.map(function () {
    this.route('contact');
    this.route('blog');
    this.route('music');
    this.route('post', {
      path: '/p/:post_slug'
    });
    this.route('projects');
    this.route('resume');
    this.route('twitch');
    this.route('deprecated-post', {
      path: '/posts/:post_slug'
    });
    this.route('about');
    this.route('deprecated-candidate', {
      path: '/the-candidate'
    });
    this.route('deprecated-dance', {
      path: '/dance'
    });
    this.route('deprecated-ember', {
      path: '/ember'
    });
    this.route('deprecated-posts', {
      path: '/posts'
    });
  });
  var _default = Router;
  _exports.default = _default;
});