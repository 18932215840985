define("jordan-hawker/services/interactivity-tracking", ["exports", "ember-interactivity/services/interactivity-tracking"], function (_exports, _interactivityTracking) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _interactivityTracking.default.extend({
    metrics: Ember.inject.service(),
    trackComponent: function trackComponent(data) {
      this.metrics.trackEvent(data);
    },
    trackRoute: function trackRoute(data) {
      this.metrics.trackEvent(data);
    }
  });

  _exports.default = _default;
});