define("jordan-hawker/templates/components/contact-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "PHXS0xIS",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[11,\"class\",[27,[[26,\"local-class\",[\"container\"],[[\"from\"],[\"jordan-hawker/styles/components/contact-list\"]]]]]],[8],[0,\"\\n  \"],[6,\"a\"],[10,\"href\",\"mailto:hawker.jordan@gmail.com\"],[8],[6,\"i\"],[10,\"class\",\"fa fa-5x fa-envelope\"],[10,\"aria-hidden\",\"true\"],[8],[9],[9],[0,\"\\n  \"],[6,\"a\"],[10,\"href\",\"https://jhawk.co/JHGitHub\"],[10,\"target\",\"_blank\"],[8],[6,\"i\"],[10,\"class\",\"fa fa-5x fa-github\"],[10,\"aria-hidden\",\"true\"],[8],[9],[9],[0,\"\\n  \"],[6,\"a\"],[10,\"href\",\"https://jhawk.co/AxleTwitter\"],[10,\"target\",\"_blank\"],[8],[6,\"i\"],[10,\"class\",\"fa fa-5x fa-twitter\"],[10,\"aria-hidden\",\"true\"],[8],[9],[9],[0,\"\\n  \"],[6,\"a\"],[10,\"href\",\"https://jhawk.co/JHFbook\"],[10,\"target\",\"_blank\"],[8],[6,\"i\"],[10,\"class\",\"fa fa-5x fa-facebook\"],[10,\"aria-hidden\",\"true\"],[8],[9],[9],[0,\"\\n  \"],[6,\"a\"],[10,\"href\",\"https://jhawk.co/JHGplus\"],[10,\"target\",\"_blank\"],[8],[6,\"i\"],[10,\"class\",\"fa fa-5x fa-google-plus\"],[10,\"aria-hidden\",\"true\"],[8],[9],[9],[0,\"\\n  \"],[6,\"a\"],[10,\"href\",\"https://jhawk.co/JHLinked\"],[10,\"target\",\"_blank\"],[8],[6,\"i\"],[10,\"class\",\"fa fa-5x fa-linkedin\"],[10,\"aria-hidden\",\"true\"],[8],[9],[9],[0,\"\\n  \"],[6,\"a\"],[10,\"href\",\"https://jhawk.co/JHStackOverflow\"],[10,\"target\",\"_blank\"],[8],[6,\"i\"],[10,\"class\",\"fa fa-5x fa-stack-overflow\"],[10,\"aria-hidden\",\"true\"],[8],[9],[9],[0,\"\\n  \"],[6,\"a\"],[10,\"href\",\"https://jhawk.co/JHSoundCloud\"],[10,\"target\",\"_blank\"],[8],[6,\"i\"],[10,\"class\",\"fa fa-5x fa-soundcloud\"],[10,\"aria-hidden\",\"true\"],[8],[9],[9],[0,\"\\n  \"],[6,\"a\"],[10,\"href\",\"https://jhawk.co/JHYouTube\"],[10,\"target\",\"_blank\"],[8],[6,\"i\"],[10,\"class\",\"fa fa-5x fa-youtube\"],[10,\"aria-hidden\",\"true\"],[8],[9],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "jordan-hawker/templates/components/contact-list.hbs"
    }
  });

  _exports.default = _default;
});