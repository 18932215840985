define("jordan-hawker/templates/components/recommendation-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "nVD+X/Uy",
    "block": "{\"symbols\":[],\"statements\":[[6,\"p\"],[11,\"class\",[27,[[26,\"local-class\",[\"text\"],[[\"from\"],[\"jordan-hawker/styles/components/recommendation-item\"]]]]]],[8],[0,\"\\\"\"],[1,[22,[\"recommendation\",\"text\"]],false],[0,\"\\\"\"],[9],[0,\"\\n\"],[6,\"div\"],[11,\"class\",[27,[[26,\"local-class\",[\"recommender\"],[[\"from\"],[\"jordan-hawker/styles/components/recommendation-item\"]]]]]],[8],[0,\"\\n  \"],[6,\"strong\"],[8],[0,\"\\n    \"],[1,[20,\"fullName\"],false],[0,\",\\n    \"],[1,[22,[\"recommendation\",\"role\"]],false],[0,\" - \"],[1,[22,[\"recommendation\",\"company\"]],false],[0,\"\\n  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "jordan-hawker/templates/components/recommendation-item.hbs"
    }
  });

  _exports.default = _default;
});