define("jordan-hawker/styles/components/header-bar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "color-dark": "#6D7993",
    "color-white": "#FFF",
    "z-index-above": "100",
    "medium": "0.4rem",
    "header-height": "2.8rem",
    "header-full-height": "(2.8rem + 2*0.4rem)",
    "header-logo-width": "(2.8rem + 0.4rem)",
    "header-bar": "_header-bar_1j1qt5",
    "header-icons": "_header-icons_1j1qt5",
    "header-logo": "_header-logo_1j1qt5"
  };
  _exports.default = _default;
});