define("jordan-hawker/components/recommendation-item", ["exports", "ember-interactivity/mixins/component-interactivity"], function (_exports, _componentInteractivity) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_componentInteractivity.default, {
    localClassNames: 'recommendation-item',
    recommendation: null,
    fullName: Ember.computed('recommendation.{firstName,lastName}', function () {
      var name = this.get('recommendation.firstName');
      var lastName = this.get('recommendation.lastName');

      if (lastName) {
        name = "".concat(name, " ").concat(lastName);
      }

      return name;
    }),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      Ember.run.scheduleOnce('afterRender', this, this.reportInteractive);
    }
  });

  _exports.default = _default;
});