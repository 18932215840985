define("jordan-hawker/templates/blog", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6f2480pM",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[10,\"class\",\"blog\"],[8],[0,\"\\n  \"],[1,[20,\"newsletter-signup\"],false],[0,\"\\n\\n\"],[4,\"if\",[[22,[\"model\",\"isLoaded\"]]],null,{\"statements\":[[0,\"    \"],[1,[26,\"tumblr-blog\",null,[[\"posts\",\"postsRoute\",\"collapsible\",\"formatDates\",\"sortBy\"],[[22,[\"model\"]],\"post\",true,true,[22,[\"sortBy\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[1,[20,\"placeholder-post\"],false],[0,\"\\n    \"],[1,[20,\"placeholder-post\"],false],[0,\"\\n    \"],[1,[20,\"placeholder-post\"],false],[0,\"\\n\"]],\"parameters\":[]}],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "jordan-hawker/templates/blog.hbs"
    }
  });

  _exports.default = _default;
});