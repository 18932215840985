define("jordan-hawker/components/header-nav-link", ["exports", "jordan-hawker/components/left-nav-link"], function (_exports, _leftNavLink) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _leftNavLink.default.extend({
    localClassNames: 'header-nav-link'
  });

  _exports.default = _default;
});