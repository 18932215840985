define("jordan-hawker/routes/music", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    isInteractive: function isInteractive(didReportInteractive) {
      return didReportInteractive('dj-promo') & didReportInteractive('dj-events');
    }
  });

  _exports.default = _default;
});