define("jordan-hawker/templates/components/header-bar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Epk0py15",
    "block": "{\"symbols\":[],\"statements\":[[6,\"h4\"],[10,\"align\",\"center\"],[3,\"action\",[[21,0,[]],\"goToIndex\"]],[8],[0,\"\\n  \"],[6,\"img\"],[11,\"class\",[27,[[26,\"local-class\",[\"header-logo\"],[[\"from\"],[\"jordan-hawker/styles/components/header-bar\"]]]]]],[10,\"src\",\"/assets/logo-small.jpg\"],[8],[9],[0,\"\\n  Jordan Hawker\\n\"],[9],[0,\"\\n\\n\"],[6,\"div\"],[11,\"class\",[27,[[26,\"local-class\",[\"header-icons\"],[[\"from\"],[\"jordan-hawker/styles/components/header-bar\"]]]]]],[8],[0,\"\\n  \"],[6,\"a\"],[10,\"href\",\"http://jhawk.co/hellfire-twitch\"],[10,\"target\",\"_blank\"],[8],[6,\"i\"],[10,\"class\",\"fa fa-2x fa-twitch\"],[10,\"aria-hidden\",\"true\"],[8],[9],[9],[0,\"\\n  \"],[6,\"a\"],[10,\"href\",\"http://jhawk.co/JHTwitter\"],[10,\"target\",\"_blank\"],[8],[6,\"i\"],[10,\"class\",\"fa fa-2x fa-twitter\"],[10,\"aria-hidden\",\"true\"],[8],[9],[9],[0,\"\\n  \"],[6,\"a\"],[10,\"href\",\"http://jhawk.co/JHLinkedIn\"],[10,\"target\",\"_blank\"],[8],[6,\"i\"],[10,\"class\",\"fa fa-2x fa-linkedin-square\"],[10,\"aria-hidden\",\"true\"],[8],[9],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "jordan-hawker/templates/components/header-bar.hbs"
    }
  });

  _exports.default = _default;
});