define("jordan-hawker/styles/components/header-nav-link", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "color-alt": "#9099A2",
    "color-dark": "#6D7993",
    "color-white": "#FFF",
    "small": "0.2rem",
    "header-nav-link": "_header-nav-link_zlwree"
  };
  _exports.default = _default;
});