define("jordan-hawker/components/disqus-comment-count", ["exports", "ember-disqus/components/disqus-comment-count"], function (_exports, _disqusCommentCount) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _disqusCommentCount.default;
    }
  });
});