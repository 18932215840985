define("jordan-hawker/adapters/tumblr-post", ["exports", "ember-tumblr/adapters/tumblr-post"], function (_exports, _tumblrPost) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _tumblrPost.default.extend({
    blogUrl: 'jordanhawker.tumblr.com',
    apiKey: 'djHwrb13Yd636cWzgzjRms29YwoB3fmHp0VXG4mB9GEAxcB6MX'
  });

  _exports.default = _default;
});