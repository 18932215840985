define("jordan-hawker/templates/components/dj-promo", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "tGA8ieQB",
    "block": "{\"symbols\":[],\"statements\":[[6,\"iframe\"],[11,\"class\",[27,[[26,\"local-class\",[\"soundcloud-embed\"],[[\"from\"],[\"jordan-hawker/styles/components/dj-promo\"]]]]]],[10,\"scrolling\",\"no\"],[10,\"frameborder\",\"no\"],[10,\"src\",\"https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/185445505&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&visual=true\"],[8],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[6,\"div\"],[11,\"class\",[27,[[26,\"local-class\",[\"dj-info\"],[[\"from\"],[\"jordan-hawker/styles/components/dj-promo\"]]]]]],[8],[0,\"\\n  \"],[6,\"p\"],[11,\"class\",[27,[[26,\"local-class\",[\"dj-about\"],[[\"from\"],[\"jordan-hawker/styles/components/dj-promo\"]]]]]],[8],[0,\"\\n    I have DJ'd all over the country for dance events and house parties.\\n    This track is one of my favorite all-time sets; please enjoy it!\\n    The biography below can be freely copied for use by event organizers and promoters:\\n  \"],[9],[0,\"\\n\\n  \"],[1,[20,\"dj-bio\"],false],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "jordan-hawker/templates/components/dj-promo.hbs"
    }
  });

  _exports.default = _default;
});