define("jordan-hawker/templates/components/tumblr-blog", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Vfgcx0V1",
    "block": "{\"symbols\":[\"post\"],\"statements\":[[4,\"if\",[[22,[\"sortedPosts\",\"length\"]]],null,{\"statements\":[[4,\"each\",[[22,[\"sortedPosts\"]]],null,{\"statements\":[[4,\"if\",[[22,[\"collapsible\"]]],null,{\"statements\":[[4,\"if\",[[22,[\"collapseByDefault\"]]],null,{\"statements\":[[0,\"        \"],[1,[26,\"tumblr-post\",null,[[\"post\",\"postsRoute\",\"collapsible\",\"collapsed\",\"formatDates\"],[[21,1,[]],[22,[\"postsRoute\"]],true,true,[22,[\"formatDates\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[1,[26,\"tumblr-post\",null,[[\"post\",\"postsRoute\",\"collapsible\",\"collapsed\",\"formatDates\"],[[21,1,[]],[22,[\"postsRoute\"]],true,false,[22,[\"formatDates\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[1,[26,\"tumblr-post\",null,[[\"post\",\"postsRoute\",\"formatDates\"],[[21,1,[]],[22,[\"postsRoute\"]],[22,[\"formatDates\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[1]},null]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[22,[\"showErrors\"]]],null,{\"statements\":[[0,\"    \"],[6,\"span\"],[10,\"class\",\"tumblr-error\"],[8],[1,[20,\"errorMessage\"],false],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "jordan-hawker/templates/components/tumblr-blog.hbs"
    }
  });

  _exports.default = _default;
});