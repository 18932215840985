define("jordan-hawker/styles/components/github-project", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "color-dark": "#6D7993",
    "very-small": "0.1rem",
    "large": "0.8rem",
    "medium": "0.4rem",
    "github-project": "_github-project_13ju6r",
    "title": "_title_13ju6r"
  };
  _exports.default = _default;
});