define("jordan-hawker/templates/components/placeholder-video", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ufQFooXA",
    "block": "{\"symbols\":[\"placeholder\"],\"statements\":[[4,\"content-placeholders\",null,null,{\"statements\":[[0,\"  \"],[1,[21,1,[\"img\"]],false],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "jordan-hawker/templates/components/placeholder-video.hbs"
    }
  });

  _exports.default = _default;
});