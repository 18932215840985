define("jordan-hawker/models/twitch-user", ["exports", "ember-data/model", "ember-data/attr"], function (_exports, _model, _attr) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    login: (0, _attr.default)('string'),
    displayName: (0, _attr.default)('string'),
    type: (0, _attr.default)('string'),
    broadcasterType: (0, _attr.default)('string'),
    description: (0, _attr.default)('string'),
    profileImageUrl: (0, _attr.default)('string'),
    offlineImageUrl: (0, _attr.default)('string'),
    email: (0, _attr.default)('string'),
    viewCount: (0, _attr.default)('number')
  });

  _exports.default = _default;
});