define("jordan-hawker/styles/components/newsletter-signup", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "color-alt": "#9099A2",
    "color-dark": "#6D7993",
    "color-white": "#FFF",
    "very-small": "0.1rem",
    "medium": "0.4rem",
    "large": "0.8rem",
    "newsletter-signup": "_newsletter-signup_797btm",
    "form": "_form_797btm",
    "email": "_email_797btm",
    "subscribe": "_subscribe_797btm"
  };
  _exports.default = _default;
});