define("jordan-hawker/styles/blog", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "color-alt": "#9099A2",
    "color-dark": "#6D7993",
    "color-white": "#FFF",
    "very-small": "0.1rem",
    "small": "0.2rem",
    "medium": "0.4rem",
    "large": "0.8rem"
  };
  _exports.default = _default;
});