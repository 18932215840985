define("jordan-hawker/components/github-projects", ["exports", "ember-interactivity/mixins/component-interactivity"], function (_exports, _componentInteractivity) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_componentInteractivity.default, {
    localClassNames: 'github-projects',
    store: Ember.inject.service(),
    loading: true,
    repos: null,
    sourceRepos: Ember.computed.filterBy('repos', 'fork', false),
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      this.set('repos', []);
      this.store.query('github-repository', {
        user: 'elwayman02',
        sort: 'updated'
      }).then(function (repos) {
        _this.set('repos', repos);

        _this.set('loading', false);
      });
    },
    isInteractive: function isInteractive(didReportInteractive) {
      return !this.loading && didReportInteractive('github-project', {
        count: this.get('sourceRepos.length')
      });
    }
  });

  _exports.default = _default;
});