define("jordan-hawker/templates/music", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8Z57aMLP",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[10,\"class\",\"music\"],[8],[0,\"\\n  \"],[1,[20,\"dj-promo\"],false],[0,\"\\n\\n  \"],[1,[20,\"dj-events\"],false],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "jordan-hawker/templates/music.hbs"
    }
  });

  _exports.default = _default;
});