define("jordan-hawker/styles/components/recommendation-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "very-small": "0.1rem",
    "small": "0.2rem",
    "medium": "0.4rem",
    "large": "0.8rem",
    "very-large": "1.2rem",
    "recommendation-item": "_recommendation-item_1eecl4",
    "text": "_text_1eecl4"
  };
  _exports.default = _default;
});