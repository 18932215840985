define("jordan-hawker/templates/components/tumblr-post-photo", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+ZT0IaY5",
    "block": "{\"symbols\":[\"photo\"],\"statements\":[[6,\"span\"],[10,\"class\",\"tumblr-title\"],[8],[0,\"\\n\"],[4,\"if\",[[22,[\"postsRoute\"]]],null,{\"statements\":[[4,\"link-to\",[[22,[\"postsRoute\"]],[22,[\"post\",\"id\"]]],null,{\"statements\":[[0,\"      \"],[1,[22,[\"post\",\"title\"]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[1,[22,[\"post\",\"title\"]],false],[0,\"\\n\"]],\"parameters\":[]}],[9],[0,\"\\n\\n\"],[6,\"span\"],[10,\"class\",\"tumblr-date\"],[8],[0,\"\\n\"],[4,\"if\",[[22,[\"formatDates\"]]],null,{\"statements\":[[0,\"    \"],[1,[26,\"moment-format\",[[22,[\"post\",\"date\"]]],null],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[1,[22,[\"post\",\"date\"]],false],[0,\"\\n\"]],\"parameters\":[]}],[9],[0,\"\\n\\n\"],[6,\"div\"],[10,\"class\",\"tumblr-body\"],[8],[0,\"\\n\"],[4,\"each\",[[22,[\"post\",\"photos\"]]],null,{\"statements\":[[0,\"      \"],[6,\"img\"],[10,\"class\",\"tumblr-photo\"],[11,\"src\",[21,1,[\"alt_sizes\",\"0\",\"url\"]],null],[8],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"\\n  \"],[6,\"span\"],[10,\"class\",\"tumblr-caption\"],[8],[0,\"\\n    \"],[1,[22,[\"post\",\"caption\"]],true],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\\n\"],[4,\"if\",[[22,[\"collapsible\"]]],null,{\"statements\":[[0,\"  \"],[6,\"button\"],[10,\"class\",\"tumblr-expand-button\"],[3,\"action\",[[21,0,[]],\"expand\"]],[8],[1,[20,\"expandButtonText\"],false],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "jordan-hawker/templates/components/tumblr-post-photo.hbs"
    }
  });

  _exports.default = _default;
});