define("jordan-hawker/serializers/twitch-user", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = _emberData.default.RESTSerializer.extend({
    keyForAttribute: function keyForAttribute(attr) {
      return Ember.String.decamelize(attr);
    },
    normalizeResponse: function normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      var wrappedPayload = _defineProperty({}, primaryModelClass.modelName, payload.data[0]);

      return this._super(store, primaryModelClass, wrappedPayload, id, requestType);
    }
  });

  _exports.default = _default;
});