define("jordan-hawker/components/twitch-embed", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    localClassNames: 'twitch-embed',
    activeUsername: 'axlehellfire',
    followUser: null,
    defaultUsernames: null,
    store: Ember.inject.service(),
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      this.set('defaultUsernames', ['axlehellfire', 'boardgames']);
      this.findActiveStream({
        user_login: this.defaultUsernames
      }).then(function (id) {
        if (id) {
          _this.loadActiveUser(id);
        } else {
          _this.findDefaultUser().then(function (id) {
            return _this.loadFollows(id);
          }).then(function (ids) {
            return _this.findActiveStream({
              user_id: ids
            });
          }).then(function (id) {
            _this.loadActiveUser(id);
          });
        }
      });
    },
    findDefaultUser: function findDefaultUser() {
      return this.findUser('axlehellfire');
    },
    findUser: function findUser(username) {
      return this.store.queryRecord('twitch-user', {
        login: username
      }).then(function (user) {
        return user.get('id');
      });
    },
    loadFollows: function loadFollows(id) {
      return this.store.query('twitch-follow', {
        from_id: id,
        first: 98
      }).then(function (follows) {
        return follows.map(function (follow) {
          return follow.get('toId');
        });
      });
    },
    findActiveStream: function findActiveStream(query) {
      return this.store.query('twitch-stream', query).then(function (streams) {
        var streamCount = streams.get('length');
        var liveId;

        if (streamCount === 1) {
          liveId = streams.get('firstObject.userId');
        } else if (streamCount > 1) {
          var id;
          streams.forEach(function (stream) {
            if (liveId) {
              return;
            }

            if (stream.get('type') === 'live') {
              liveId = stream.get('userId');
            } else {
              id = stream.get('userId');
            }
          });

          if (!liveId) {
            liveId = id;
          }
        }

        return liveId;
      });
    },
    loadActiveUser: function loadActiveUser(id) {
      var _this2 = this;

      this.store.queryRecord('twitch-user', {
        id: id
      }).then(function (user) {
        _this2.set('activeUsername', user.get('login'));

        if (!_this2.defaultUsernames.includes(user.get('login'))) {
          _this2.set('followUser', user);
        }
      });
    },
    embedUrl: Ember.computed('activeUsername', function () {
      return "https://embed.twitch.tv/?channel=".concat(this.activeUsername, "&theme=dark");
    }),
    axleButtonClass: Ember.computed('activeUsername', function () {
      if (this.activeUsername === 'axlehellfire') {
        return 'active';
      }
    }),
    tiltedButtonClass: Ember.computed('activeUsername', function () {
      if (this.activeUsername === 'boardgames') {
        return 'active';
      }
    }),
    followButtonClass: Ember.computed('{activeUsername,followUser}', function () {
      if (this.activeUsername === this.get('followUser.login')) {
        return 'active';
      }
    }),
    actions: {
      toggle: function toggle(username) {
        this.set('activeUsername', username);
      }
    }
  });

  _exports.default = _default;
});