define("jordan-hawker/styles/components/contact-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "color-alt": "#9099A2",
    "color-dark": "#6D7993",
    "medium": "0.4rem",
    "contact-list": "_contact-list_qkx0an",
    "container": "_container_qkx0an"
  };
  _exports.default = _default;
});