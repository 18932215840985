define("jordan-hawker/components/recommendation-carousel", ["exports", "ember-interactivity/mixins/component-interactivity"], function (_exports, _componentInteractivity) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_componentInteractivity.default, {
    store: Ember.inject.service(),
    loading: true,
    recommendations: null,
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      this.set('recommendations', []);
      this.store.findAll('recommendation').then(function (recommendations) {
        _this.set('recommendations', recommendations);

        _this.set('loading', false);
      });
    },
    isInteractive: function isInteractive(didReportInteractive) {
      return !this.loading && didReportInteractive('recommendation-item');
    }
  });

  _exports.default = _default;
});