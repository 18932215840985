define("jordan-hawker/controllers/blog", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    init: function init() {
      this._super.apply(this, arguments);

      this.set('sortBy', ['date:desc']);
    }
  });

  _exports.default = _default;
});