define("jordan-hawker/templates/twitch", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2HeZs/gU",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[10,\"class\",\"twitch\"],[8],[0,\"\\n  \"],[1,[20,\"twitch-embed\"],false],[0,\"\\n\\n  \"],[6,\"div\"],[10,\"class\",\"twitch-container\"],[8],[0,\"\\n    \"],[1,[26,\"twitch-info\",null,[[\"username\"],[\"axlehellfire\"]]],false],[0,\"\\n\\n    \"],[1,[26,\"twitch-info\",null,[[\"username\"],[\"boardgames\"]]],false],[0,\"\\n  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "jordan-hawker/templates/twitch.hbs"
    }
  });

  _exports.default = _default;
});