define("jordan-hawker/templates/components/footer-bar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "KMhCeIAg",
    "block": "{\"symbols\":[],\"statements\":[[6,\"p\"],[8],[6,\"i\"],[10,\"class\",\"fa fa-1x fa-copyright\"],[10,\"aria-hidden\",\"true\"],[8],[9],[0,\" Jordan Hawker 2015 - \"],[1,[20,\"currentYear\"],false],[9],[0,\"\\n\"],[6,\"p\"],[8],[0,\"\\n  Built with cutting-edge versions of\\n  \"],[6,\"a\"],[10,\"href\",\"http://emberjs.com/\"],[10,\"target\",\"_blank\"],[8],[6,\"img\"],[11,\"class\",[27,[[26,\"local-class\",[\"brand-icon\"],[[\"from\"],[\"jordan-hawker/styles/components/footer-bar\"]]]]]],[10,\"src\",\"/assets/ember.png\"],[8],[9],[9],[0,\"\\n  \"],[6,\"a\"],[10,\"href\",\"http://www.ember-cli.com/\"],[10,\"target\",\"_blank\"],[8],[6,\"img\"],[11,\"class\",[27,[[26,\"local-class\",[\"brand-icon\"],[[\"from\"],[\"jordan-hawker/styles/components/footer-bar\"]]]]]],[10,\"src\",\"/assets/ember-cli.png\"],[8],[9],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[6,\"p\"],[8],[0,\"\\n  Fully open-source on \"],[6,\"a\"],[11,\"class\",[27,[[26,\"local-class\",[\"github\"],[[\"from\"],[\"jordan-hawker/styles/components/footer-bar\"]]]]]],[10,\"href\",\"http://jhawk.co/jordan-hawker-github\"],[10,\"target\",\"_blank\"],[8],[6,\"i\"],[10,\"class\",\"fa fa-1x fa-github\"],[10,\"aria-hidden\",\"true\"],[8],[9],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[6,\"p\"],[8],[0,\"Logo courtesy of \"],[6,\"a\"],[10,\"href\",\"http://www.daniinthecityphotography.com/\"],[10,\"target\",\"_blank\"],[8],[0,\"DANIINTHECITY Photography\"],[9],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "jordan-hawker/templates/components/footer-bar.hbs"
    }
  });

  _exports.default = _default;
});