define("jordan-hawker/models/twitch-video", ["exports", "ember-data/model", "ember-data/attr"], function (_exports, _model, _attr) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    userId: (0, _attr.default)('number'),
    title: (0, _attr.default)('string'),
    description: (0, _attr.default)('string'),
    thumbnailUrl: (0, _attr.default)('string'),
    language: (0, _attr.default)('string'),
    duration: (0, _attr.default)('string'),
    viewCount: (0, _attr.default)('number'),
    createdAt: (0, _attr.default)('date'),
    publishedAt: (0, _attr.default)('date'),
    thumbnailImg: Ember.computed('thumbnailUrl', function () {
      return this.thumbnailUrl.replace('%{width}', '160').replace('%{height}', '90');
    }),
    url: Ember.computed('id', function () {
      return "https://www.twitch.tv/videos/".concat(this.id);
    })
  });

  _exports.default = _default;
});