define("jordan-hawker/templates/components/left-nav", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "jaMxqEnB",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[11,\"class\",[27,[[26,\"local-class\",[\"left-nav-body left-nav-link-list\"],[[\"from\"],[\"jordan-hawker/styles/components/left-nav\"]]]]]],[8],[0,\"\\n  \"],[4,\"left-nav-link\",[\"index\"],null,{\"statements\":[[0,\"Home\"]],\"parameters\":[]},null],[0,\"\\n  \"],[4,\"left-nav-link\",[\"blog\"],null,{\"statements\":[[0,\"Blog\"]],\"parameters\":[]},null],[0,\"\\n  \"],[4,\"left-nav-link\",[\"projects\"],null,{\"statements\":[[0,\"Projects\"]],\"parameters\":[]},null],[0,\"\\n  \"],[4,\"left-nav-link\",[\"twitch\"],null,{\"statements\":[[0,\"Twitch\"]],\"parameters\":[]},null],[0,\"\\n  \"],[4,\"left-nav-link\",[\"music\"],null,{\"statements\":[[0,\"Music\"]],\"parameters\":[]},null],[0,\"\\n  \"],[4,\"left-nav-link\",[\"resume\"],null,{\"statements\":[[0,\"Resume\"]],\"parameters\":[]},null],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[6,\"div\"],[11,\"class\",[27,[[26,\"local-class\",[\"left-nav-footer left-nav-link-list\"],[[\"from\"],[\"jordan-hawker/styles/components/left-nav\"]]]]]],[8],[0,\"\\n  \"],[4,\"left-nav-link\",[\"about\"],null,{\"statements\":[[0,\"About\"]],\"parameters\":[]},null],[0,\"\\n  \"],[4,\"left-nav-link\",[\"contact\"],null,{\"statements\":[[0,\"Contact\"]],\"parameters\":[]},null],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "jordan-hawker/templates/components/left-nav.hbs"
    }
  });

  _exports.default = _default;
});