define("jordan-hawker/templates/components/placeholder-project", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "9j0fThYd",
    "block": "{\"symbols\":[\"placeholder\"],\"statements\":[[4,\"content-placeholders\",null,[[\"centered\",\"rounded\"],[true,true]],{\"statements\":[[0,\"  \"],[1,[21,1,[\"heading\"]],false],[0,\"\\n  \"],[1,[26,\"component\",[[21,1,[\"text\"]]],[[\"lines\"],[2]]],false],[0,\"\\n  \"],[1,[26,\"component\",[[21,1,[\"text\"]]],[[\"lines\"],[1]]],false],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "jordan-hawker/templates/components/placeholder-project.hbs"
    }
  });

  _exports.default = _default;
});