define("jordan-hawker/templates/components/dj-event", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "zqUc0UXX",
    "block": "{\"symbols\":[],\"statements\":[[6,\"span\"],[11,\"class\",[27,[[26,\"local-class\",[\"name\"],[[\"from\"],[\"jordan-hawker/styles/components/dj-event\"]]]]]],[8],[6,\"strong\"],[8],[1,[22,[\"event\",\"name\"]],false],[9],[9],[0,\"\\n\"],[6,\"span\"],[11,\"class\",[27,[[26,\"local-class\",[\"location\"],[[\"from\"],[\"jordan-hawker/styles/components/dj-event\"]]]]]],[8],[1,[22,[\"event\",\"location\"]],false],[9],[0,\"\\n\"],[6,\"span\"],[11,\"class\",[27,[[26,\"local-class\",[\"dance-styles\"],[[\"from\"],[\"jordan-hawker/styles/components/dj-event\"]]]]]],[8],[1,[20,\"danceStyles\"],false],[9],[0,\"\\n\"],[6,\"span\"],[11,\"class\",[27,[[26,\"local-class\",[\"name\"],[[\"from\"],[\"jordan-hawker/styles/components/dj-event\"]]]]]],[8],[1,[20,\"dates\"],false],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "jordan-hawker/templates/components/dj-event.hbs"
    }
  });

  _exports.default = _default;
});