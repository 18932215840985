define("jordan-hawker/mirage-models/github-commit", ["exports", "ember-data-github/mirage-models/github-commit"], function (_exports, _githubCommit) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _githubCommit.default;
    }
  });
});