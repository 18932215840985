define("jordan-hawker/templates/components/github-projects", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2ogw+x21",
    "block": "{\"symbols\":[\"repo\"],\"statements\":[[4,\"each\",[[22,[\"sourceRepos\"]]],null,{\"statements\":[[0,\"  \"],[1,[26,\"github-project\",null,[[\"repo\"],[[21,1,[]]]]],false],[0,\"\\n\"]],\"parameters\":[1]},{\"statements\":[[0,\"  \"],[1,[20,\"placeholder-project\"],false],[0,\"\\n  \"],[1,[20,\"placeholder-project\"],false],[0,\"\\n  \"],[1,[20,\"placeholder-project\"],false],[0,\"\\n  \"],[1,[20,\"placeholder-project\"],false],[0,\"\\n  \"],[1,[20,\"placeholder-project\"],false],[0,\"\\n  \"],[1,[20,\"placeholder-project\"],false],[0,\"\\n  \"],[1,[20,\"placeholder-project\"],false],[0,\"\\n  \"],[1,[20,\"placeholder-project\"],false],[0,\"\\n  \"],[1,[20,\"placeholder-project\"],false],[0,\"\\n  \"],[1,[20,\"placeholder-project\"],false],[0,\"\\n  \"],[1,[20,\"placeholder-project\"],false],[0,\"\\n  \"],[1,[20,\"placeholder-project\"],false],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "jordan-hawker/templates/components/github-projects.hbs"
    }
  });

  _exports.default = _default;
});