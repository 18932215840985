define("jordan-hawker/components/front-post", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    post: null,
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      this.store.queryRecord('tumblr-post-text', {
        limit: 1
      }).then(function (post) {
        _this.set('post', post);
      });
    }
  });

  _exports.default = _default;
});